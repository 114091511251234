<template>
  <div
    v-loading="loading"
    :element-loading-text="loadingTitle"
    class="app-container"
  >
    <div class="white-container clearfix">
      <el-row style="margin-top: 20px;display:flex">
        <div style="margin-right:10px">
        发布日期：
        <el-date-picker
          clearable
          size="small"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          v-model="activityDateRange"
          type="daterange"
          align="right"
          :editable="false"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="selectTimeRange"
        >
        </el-date-picker>
        </div>
         <div style="margin-right:10px">
        素材类型：
        <el-select
          size="small"
          v-model="materialType"
          clearable
          placeholder="选择素材类型"
        >
          <el-option
            v-for="item in options6"
            :key="item.id"
            :label="item.type_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
         </div>
          <div style="margin-right:10px">
        状态选择：
        <el-select
          size="small"
          v-model="zhuangtai"
          clearable
          placeholder="选择素材类型"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
         </div>
             <div style="margin-right:10px">
        是否热门：
        <el-select
          size="small"
          v-model="hot"
          clearable
          placeholder="选择是否热门"
        >
          <el-option
            v-for="item in optionshot"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
         </div>
          <div style="margin-right:10px">
        素材标题：<el-input
          v-model="searchTitle"
          clearable
          style="width: 200px"
          size="small"
          placeholder="请输入搜索标题"
        />
         </div>
      
      </el-row>
        <el-row>
        
          <el-button
            size="small"
            @click="addMaterial"
            icon="el-icon-plus"
            type="primary"
          >
            添加素材内容
          </el-button>
     
          <el-button
            size="small"
            style="margin-left: 20px; margin-top: 20px"
            @click="serach"
            icon="el-icon-search"
            type="primary"
          >
            搜索
          </el-button>
        </el-row>
      <div class="neirong">
        <div class="left">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="title" label="素材标题" width="auto">
            </el-table-column>
            <el-table-column prop="day" label="发布日期" width="auto">
            </el-table-column>
            <el-table-column prop="see" label="浏览量" width="100">
            </el-table-column>
            <el-table-column prop="download" label="下载量" width="100">
            </el-table-column>
            <el-table-column prop="status_value" label="素材状态" width="100">
            </el-table-column>
            <el-table-column prop="is_hot" label="是否热门" width="100">
              <template slot-scope="scope">
                 <el-switch
                  v-model="scope.row.is_hot"
                  disabled
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
              </el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="reback_reason" label="驳回原因" width="auto">
            </el-table-column>

            <el-table-column label="操作" width="auto">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.status == 1"
                  @click="check(scope.row)"
                  type="warning"
                  size="small"
                >
                  <i class="el-icon-s-promotion"></i>
                  审核
                </el-button>
                <el-button
                  @click="lookdetail(scope.row)"
                  type="primary"
                  size="small"
                >
                  <i class="el-icon-edit-outline"></i>
                  修改或查看
                </el-button>
                <el-button
                v-if="scope.row.status == 3 && scope.row.status != 1"
                  @click="upSucai(scope.row)"
                  type="success"
                  size="small"
                >
                  上架
                </el-button>
                <el-button
                    v-if="scope.row.status == 2 && scope.row.status != 1"
                  @click="downSucai(scope.row)"
                  type="warning"
                  size="small"
                >
                  下架
                </el-button>
                <el-button
                v-if="scope.row.status != 1"
                  @click="delSucai(scope.row)"
                  type="danger"
                  size="small"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-wrapper">
            <el-pagination
              :current-page="page"
              :page-sizes="[10, 20]"
              :page-size="limit"
              background
              layout="total, sizes, prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 素材内容新增抽屉 -->
    <el-drawer
      size="80"
      title="新增素材"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      :mask-closable="false"
    >
      <el-form
        style="margin-top: 20px"
        ref="form"
        :model="form"
        label-width="80px"
      >
        <el-form-item label="发布日期" required>
          <el-form-item>
            <el-date-picker
              type="date"
              placeholder="选择发布日期"
              v-model="form.day"
              style="width: 300px"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-form-item>
        <el-form-item label="素材类型" required>
          <el-select
            style="width: 300px"
            clear
            v-model="form.type_id"
            clearable
            placeholder="请选择素材类型"
          >
            <el-option
              v-for="item in options6"
              :key="item.id"
              :label="item.type_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="素材标题" required>
          <el-input
            style="width: 300px"
            v-model="form.title"
            placeholder="请输入素材标题"
          ></el-input>
        </el-form-item>
              <el-form-item label="素材来源" required>
               <el-select
          size="small"
          v-model="form.source_type"
          clearable
          placeholder="选择素材来源"
        >
          <el-option
            v-for="item in sourceList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
        </el-form-item>
              <el-form-item label="是否热门" required>
 <el-switch
  v-model="form.is_hot"
  :active-value="true"
  :inactive-value="false"
  active-color="#13ce66"
  inactive-color="#ff4949">
</el-switch>
        </el-form-item>
    

        <el-form-item label="素材内容" required>
          <vue-ueditor-wrap v-model="form.contents" :config="myConfig" />
        </el-form-item>

        <el-form-item label="素材图:" required>
          <div class="flex-c-s" style="align-items: start">
            <div
              v-for="(item, index) in form.image_list"
              :key="index"
              class="mr15"
            >
              <el-upload
                class="avatar-uploader"
                :action="upload_url"
                :show-file-list="false"
                :on-success="
                  (response, file, fileList) =>
                    handleAvatarSuccess(response, file, fileList, index)
                "
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="item" :src="item" class="avatar" />
                <img
                  v-if="item"
                  src="@/button/img_del.png"
                  class="img-del"
                  @click.stop="delImg2(index)"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </div>
          </div>
          <div class="tip-yellow">
            温馨提示：只能上传jpg、png、jpeg文件，最少1张最多9张
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit('form')"
            >立即添加</el-button
          >
          <el-button @click="drawer = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
    <!-- 素材内容修改抽屉 -->
    <el-drawer
      size="80"
      title="修改素材"
      :visible.sync="drawerChange"
      :direction="direction"
      :before-close="handleClose"
    >
      <el-form
        style="margin-top: 20px"
        ref="form"
        :model="form"
        label-width="80px"
      >
        <el-form-item label="发布日期" required>
          <el-form-item>
            <el-date-picker
              type="date"
              placeholder="选择发布日期"
              v-model="detail.day"
              style="width: 300px"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-form-item>
        <el-form-item label="素材类型" required>
          <el-select
            style="width: 300px"
            clear
            v-model="detail.type_id"
            clearable
            placeholder="请选择素材类型"
          >
            <el-option
              v-for="item in options6"
              :key="item.id"
              :label="item.type_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="素材标题" required>
          <el-input
            style="width: 300px"
            v-model="detail.title"
            placeholder="请输入素材标题"
          ></el-input>
        </el-form-item>
                    <el-form-item label="素材来源" required>
               <el-select
          size="small"
          v-model="detail.source_type"
          clearable
          placeholder="选择素材来源"
        >
          <el-option
            v-for="item in sourceList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
        </el-form-item>
                      <el-form-item label="是否热门" required>
 <el-switch
  v-model="detail.is_hot"
  :active-value="1"
  :inactive-value="0"
  active-color="#13ce66"
  inactive-color="#ff4949">
</el-switch>
        </el-form-item>
        <el-form-item label="素材内容:" required>
          <vue-ueditor-wrap v-model="detail.contents" :config="myConfig" />
        </el-form-item>

        <el-form-item label="素材图:" required>
          <div class="flex-c-s" style="align-items: start">
            <div
              v-for="(item, index) in detail.image_list"
              :key="index"
              class="mr15"
            >
              <el-upload
                class="avatar-uploader"
                :action="upload_url"
                :show-file-list="false"
                :on-success="
                  (response, file, fileList) =>
                    handleAvatarSuccess3(response, file, fileList, index)
                "
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="item" :src="item" class="avatar" />
                <img
                  v-if="item"
                  src="@/button/img_del.png"
                  class="img-del"
                  @click.stop="delImg3(index)"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </div>
          </div>
          <div class="tip-yellow">
            温馨提示：只能上传jpg、png、jpeg文件，最少1张最多9张
          </div>
        </el-form-item>
        <!-- 排序素材图 -->
         <el-form-item>
            <el-transfer  target-order="push" @change="changSort" v-model="value" :data="data" :titles="['排序前','排序后']"></el-transfer>
         </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onChange('form')"
            >立即修改</el-button
          >
          <el-button @click="drawerChange = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>

    <!-- 新增类型对话框 -->
    <el-dialog
      :title="typeTitle"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span v-if="typeTitle == '审核提示'">审核处理：</span>
      <el-radio-group
        v-if="typeTitle == '审核提示'"
        v-model="checkStus"
        @change="changeCHck"
      >
        <el-radio label="通过"></el-radio>
        <el-radio label="驳回"></el-radio>
        <el-radio label="删除"></el-radio>
      </el-radio-group>
      <div style="margin-top: 20px" v-if="checkStus == '驳回'">
        <span style="margin-right: 10px">驳回原因:</span
        ><el-input
          clearable
          style="width: 300px"
          v-model="reject"
          placeholder="请输入驳回原因"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureAddName">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  gettype,
  sucaiAdd,
  sucaiList,
  changeStatus,
  editone,
  addbaner,
  getbaner,
  editbaner,
  sourcelist
} from "@/Api/operation";
import { MessageBox } from "element-ui";
import { ceshi } from "@/utils/requset";
import VueUeditorWrap from "vue-ueditor-wrap";
import config from "@/utils/ueditorConfig.js";
import { Message } from "element-ui";
export default {
  components: {
    VueUeditorWrap,
  },
  data() {
    return {
      num:1,
      hot:'',
      value:[], // 排序后
      data:[],// 排序前
      sourceList:[],
      myConfig: config,
      drawerChange: false,
      addBanners: true,
      changebanID: "",
      typeOne_id: "",
      loadingTitle: "加载中...",
      iconList: [""],
      detail: {
        id: "",
        title: "", // 素材标题
        contents: "", // 素材内容
        image_list: ["", "", "", "", "", "", "", "", ""], // 素材图片
        day: "", // 日期
        type_id: "", // 添加 修改 的素材类型的值
        source_type:'', // 素材来源
       is_hot:false // 是否热门
      },
      checkStus: "",
      zhuangtai: "",
      reject: "",
      optionshot:[{
        name:'是',
        id:1
      },{
          name: "否",
          id: 0,
        },],
      options: [
        {
          name: "待上架",
          id: 1,
        },
        {
          name: "已上架",
          id: 2,
        },
        {
          name: "已下架",
          id: 3,
        },
        {
          name: "已删除",
          id: 4,
        },
      ],
      searchTitle: "",
      inputName: "", // 添加类型名称
      typeTitle: "",
      placeholder: "",
      dialogVisible: false, // 对话框
      upload_url: ceshi + "/common/uploadFile/upload",
      drawer: false, // 抽屉
      direction: "rtl",
      // 素材二级分类下拉列表
      options6: [],
      options7: [], // 一级分类下拉列表
      materialType: "", // 素材类型
      materialType2: "", // 删除素材类型
      form: {
        title: "", // 素材标题
        contents: "", // 素材内容
        image_list: ["", "", "", "", "", "", "", "", ""], // 素材图片
        day: "", // 日期
        type_id: "", // 添加 修改 的素材类型的值
        source_type:'', // 素材来源
        is_hot:false // 是否是热门
      },
      clearForm:{
          title: "", // 素材标题
        contents: "", // 素材内容
        image_list: ["", "", "", "", "", "", "", "", ""], // 素材图片
        day: "", // 日期
        type_id: "", // 添加 修改 的素材类型的值
        source_type:'', // 素材来源
        is_hot:false
      },
      activityDateRange: null,
      loading: false,
      page: 1,
      limit: 10,
      total: 0,
      tableData: [],
      begtime: "",
      endtime: "",
      checkId: "",
    };
  },
  methods: {
    // 上传素材图
    handleAvatarSuccess(res, file, fileList, index) {
      // this.form.banner_chart[index] = res.data.fullurl
      this.loading = false;
      this.loadingTitle = "加载中...";
      if (res.code != 1) {
        Message({
          message: res.msg,
          type: "warning",
        });
        return;
      }
      const arrTemp = JSON.parse(JSON.stringify(this.form.image_list));
      arrTemp[index] = res.data.fullurl;
      this.form.image_list = arrTemp;
      console.log(this.form.image_list);
    },
    // 修改素材内容 上传素材图
    handleAvatarSuccess3(res, file, fileList, index) {
      this.loading = false;
      this.loadingTitle = "加载中...";
      // this.form.banner_chart[index] = res.data.fullurl
      if (res.code != 1) {
        Message({
          message: res.msg,
          type: "warning",
        });
        return;
      }
      const arrTemp = JSON.parse(JSON.stringify(this.detail.image_list));
      arrTemp[index] = res.data.fullurl;
      this.detail.image_list = arrTemp;
      console.log(this.form.image_list);
    },
    // 上传轮播
    handleAvatarSuccess4(res, file, fileList, index) {
      this.loading = false;
      this.loadingTitle = "加载中...";
      // this.form.banner_chart[index] = res.data.fullurl
      if (res.code != 1) {
        Message({
          message: res.msg,
          type: "warning",
        });
        return;
      }
      const arrTemp = JSON.parse(JSON.stringify(this.swiperList));
      arrTemp[index] = res.data.fullurl;
      this.swiperList = arrTemp;
      console.log(this.swiperList);
    },
    // 上传icon
    handleAvatarSuccess5(res, file, fileList, index) {
      this.loading = false;
      this.loadingTitle = "加载中...";
      // this.form.banner_chart[index] = res.data.fullurl
      if (res.code != 1) {
        Message({
          message: res.msg,
          type: "warning",
        });
        return;
      }
      const arrTemp = JSON.parse(JSON.stringify(this.iconList));
      arrTemp[index] = res.data.fullurl;
      this.iconList = arrTemp;
    },
    // 删除素材图
    delImg2(index) {
      console.log(index);
      const arrTemp = JSON.parse(JSON.stringify(this.form.image_list));
      arrTemp[index] = "";
      this.form.image_list = arrTemp;
    },
    // 修改内容删除素材图
    delImg3(index) {
      console.log(index);
      const arrTemp = JSON.parse(JSON.stringify(this.detail.image_list));
      arrTemp[index] = "";
      this.detail.image_list = arrTemp;
    },
    // 修改内容删除轮播图
    delImg4(index) {
      console.log(index);
      const arrTemp = JSON.parse(JSON.stringify(this.swiperList));
      arrTemp[index] = "";
      this.swiperList = arrTemp;
    },
    // 删除icon
    delImg5(index) {
      console.log(index);
      const arrTemp = JSON.parse(JSON.stringify(this.iconList));
      arrTemp[index] = "";
      this.iconList = arrTemp;
    },
    // 获取素材资源列表
    getSourceList(){
      sourcelist({}).then(res=>{
        if(res.code == 1){
          this.sourceList = res.data
        }
      })
    },
    beforeAvatarUpload(file) {
      this.loading = true;
      this.loadingTitle = "上传中...";
      console.log(file);
      // const isLt5M = file.size / 1024 / 1024 < 2;
      // if (!isLt5M) {
      //   this.$message.error("上传图片大小不能超过 2MB!");
      // }
      // return isLt5M;
    },

    changeSwiper() {
      let nullNum = 0;
      this.swiperList.forEach((item) => {
        if (item == "") {
          nullNum++;
        }
      });
      if (nullNum == 6) {
        console.log("轮播图片不能为空");
        Message({
          message: "轮播图片不能为空",
          type: "error",
        });
        return;
      }
      // 添加操作
      if (this.addBanners) {
        addbaner({
          list: this.swiperList,
        }).then((res) => {
          if (res.code == 1) {
            Message({
              message: "操作成功",
              type: "success",
            });
            this.addBanners = false;
            return;
          }
        });
      }
      // 修改操作
      editbaner({
        id: this.changebanID,
        list: this.swiperList,
      }).then((res) => {
        if (res.code == 1) {
          Message({
            message: "操作成功",
            type: "success",
          });
        }
      });
    },

    changeCHck(e) {
      console.log(e);
    },
    check(row) {
      this.typeTitle = "审核提示";
      this.dialogVisible = true;
      this.checkId = row.id;
    },
    // 修改素材内容
    onChange() {
      let { title, type_id, image_list, contents, day,source_type} = this.detail;

      if (!title) {
        Message({
          message: "标题不能为空",
          type: "error",
        });
        return;
      }
      
      if (!type_id) {
        Message({
          message: "素材类型不能为空",
          type: "error",
        });
        return;
      }
      if (!source_type) {
        Message({
          message: "素材来源不能为空",
          type: "error",
        });
        return;
      }
      if (image_list[0] == "") {
        Message({
          message: "素材图不能为空",
          type: "error",
        });
        return;
      }
      if (!contents) {
        Message({
          message: "素材内容不能为空",
          type: "error",
        });
        return;
      }
      if (!day) {
        Message({
          message: "发布日期不能为空",
          type: "error",
        });
        return;
      }
          if(this.value.length > 0 && this.value.length < this.detail.image_list.length){
        Message({
          message: "排序操作,需要全部进行排序",
          type: "error",
        });
        return
      }
      // console.log(this.detail.image_list);
      let arr = []
      if(this.value.length > 0 && this.value.length  == this.detail.image_list.length){
        this.value.forEach(item=>{
          arr.push(this.detail.image_list[item])
        })
        this.detail.image_list = arr
      }
      editone(this.detail)
        .then((res) => {
          if (res.code == 1) {
            Message({
              message: "操作成功",
              type: "success",
            });
            this.value = []
            this.drawerChange = false;
            this.getList();
          }
        })
        .catch(() => {
          Message({
            message: "操作失败",
            type: "error",
          });
        });
    },
    selectTimeRange(timeArr) {
      if (timeArr) {
        const timeArr1 = JSON.parse(JSON.stringify(timeArr));
        timeArr1[0] = timeArr1[0] / 1000;
        timeArr1[1] = timeArr1[1] / 1000;
        timeArr1[1] = timeArr1[1] + 86399;

        // var dateRange = `${timeArr1[0]},${timeArr1[1]}`;
        this.begtime = this.activityDateRange[0];
        this.endtime = this.activityDateRange[1];
      }
    },
    // 上架素材
    upSucai(row) {
           let that = this
            changeStatus({
              id: row.id,
              status:2,
            }).then((res) => {
              if (res.code == 1) {
                Message({
                  message: res.msg,
                  type: "success",
                });
                that.getList()
              }
            });
    },
    // 下级素材
    downSucai(row){
         let that = this
            MessageBox({
        title: "提示",
        type: "warning",
        message: "确定下架素材吗？",
        callback(res) {
          if (res == "confirm") {
            changeStatus({
              id: row.id,
              status:3,
            }).then((res) => {
              if (res.code == 1) {
                Message({
                  message: res.msg,
                  type: "success",
                });
                that.getList()
              }
            });
          }
        },
      });
    },
    // 删除素材
    delSucai(row){
      let that = this
  MessageBox({
        title: "提示",
        type: "warning",
        message: "确定删除素材吗？",
        callback(res) {
          if (res == "confirm") {
            changeStatus({
              id: row.id,
              status:4,
            }).then((res) => {
              if (res.code == 1) {
                Message({
                  message: res.msg,
                  type: "success",
                });
                that.getList()
              }
            });
          }
        },
      });
    },
    // 获取素材列表
    getList() {
      sucaiList({
        title: this.searchTitle,
        status: this.zhuangtai,
        type_id: this.materialType,
        is_hot:this.hot,
        limit: this.limit,
        page: this.page,
        day: this.activityDateRange
          ? this.activityDateRange[0] + "," + this.activityDateRange[1]
          : "",
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 1) {
            this.total = res.data.total;
            this.tableData = res.data.list;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取一级分类
    getOneType() {
      gettype({ level: 1 })
        .then((res) => {
          if (res.code == 1) {
            res.data.unshift({ type_name: "顶级分类", id: 0 });
            this.options7 = res.data;
          }
        })
        .catch(() => {});
    },
    // 获取二级素材分类
    getTwoType() {
      gettype({ level: 2 })
        .then((res) => {
          if (res.code == 1) {
            this.options6 = res.data;
          }
        })
        .catch(() => {});
    },

    // 添加/添加 类型名称  审核操作
    sureAddName() {
      // 审核操作
      if (this.typeTitle == "审核提示") {
        let stu = "";
        switch (this.checkStus) {
          case "通过":
            stu = 2;
            break;
          case "驳回":
            stu = 3;

            break;
          case "删除":
            stu = 4;
            break;

          default:
            break;
        }
        if (stu == 3 && !this.reject) {
          Message({
            message: "驳回原因不能为空",
            type: "error",
          });
          return;
        }
        changeStatus({
          id: this.checkId,
          status: stu,
          reback_reason: this.reject,
        })
          .then((res) => {
            if (res.code == 1) {
              Message({
                message: "操作成功",
                type: "success",
              });
              this.getList();
              this.dialogVisible = false;
            }
          })
          .catch(() => {});
        return;
      }
    },
    handleClose(done) {
      done();
    },
    // 添加素材内容
    addMaterial() {
      this.drawer = true;
    },
    serach() {
      this.page = 1;
      this.loading = true;
      this.getList();
    },
           changSort(e){
            this.value = e
      },
    onSubmit() {
      let that = this;
      let { title, contents, image_list, day, type_id,source_type} = that.form;
      if (!title) {
        Message({
          message: "素材标题不能为空",
          type: "error",
        });
        return;
      }
        if (!source_type) {
        Message({
          message: "素材来源不能为空",
          type: "error",
        });
        return;
      }
      if (!contents) {
        Message({
          message: "素材内容不能为空",
          type: "error",
        });
        return;
      }
      let nullNum = 0;
      image_list.forEach((item) => {
        if (item == "") {
          nullNum++;
        }
      });
      if (nullNum == 9) {
        Message({
          message: "素材图片不能为空",
          type: "error",
        });
        return;
      }
      if (!day) {
        Message({
          message: "发布日期不能为空",
          type: "error",
        });
        return;
      }
      if (!type_id) {
        Message({
          message: "素材类型不能为空",
          type: "error",
        });
        return;
      }
      sucaiAdd(that.form)
        .then((res) => {
          if (res.code == 1) {
            Message({
              message: "操作成功",
              type: "success",
            });
            that.drawer = false;
            that.form = that.clearForm
            that.getList();
          } else {
            Message({
              message: "操作失败",
              type: "error",
            });
          }
        })
        .catch(() => {
          Message({
            message: "操作失败",
            type: "error",
          });
        });
    },
    lookdetail(row) {
      let data = JSON.parse(JSON.stringify(row));
      // console.log(data);
      this.detail["id"] = data.id;
      this.detail["title"] = data.title;
      this.detail["type_id"] = data.type_id;
      this.detail["image_list"] = data.image_list_arr;
      this.detail["contents"] = data.contents;
      this.detail["day"] = data.day;
      this.detail["is_hot"] = data.is_hot;
      this.detail["source_type"] = data.source_type;
      const arr = []
      for (let index = 0; index < data.image_list_arr.length; index++) {
      arr.push({key:index,label:`第${index+1}张`})
      }
      this.data = arr
      // this.data = ;
      // console.log(this.detail);
      // // this.detail = data
      this.drawerChange = true;
    },

    // 改变页码
    handleCurrentChange(val) {
      this.loading = true;
      this.page = val;
      this.getList();
    },
    // 改变一页的数量
    handleSizeChange(val) {
      this.loading = true;
      this.page = 1;
      this.limit = val;
      this.getList();
    },
    // 检查是否存在banner列表
    checkBanner() {
      getbaner({}).then((res) => {
        if (res.data.url.length > 0) {
          this.changebanID = res.data.id;
          this.swiperList = res.data.url;
          this.addBanners = false;
        } else {
          this.addBanners = true;
        }
      });
    },
  },
  delayedFunction() {
 location.reload(true)
  // 在这里编写你的操作逻辑
},
  async created() {
    this.getOneType(); // 获取一级分类
    this.getTwoType(); // 获取二级分类
    this.getList();
    this.checkBanner();
    this.getSourceList();
            if (location.href.indexOf("#reloaded") == -1) {
                location.href = location.href + "#reloaded";
                location.reload();
            }
  },

};
</script>
<style lang="scss" >
.app-container {
  .white-container {
    height: 100vh;
    overflow: auto;
    .add {
      margin-top: 20px;
    }
    .neirong {
      min-width: 600px;
      min-height: 600px;
      margin-top: 20px;
      display: flex;
      .left {
        width: 100%;
      }
    }
  }
}
</style>
