<template>
  <div
    v-loading="loading"
    :element-loading-text="loadingTitle"
    class="app-container"
  >
    <div class="white-container clearfix">
      <span style="font-size:14px">分类：</span> 
      <el-select
        @change="changeSelect"
        size="small"
        clearable
        style=" margin-top: 10px"
        v-model="value2"
        placeholder="选择分类获取二级分类"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.type_name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-button
        size="small"
        @click="addMaterialType(1)"
        icon="el-icon-plus"
        type="info"
        style="margin-left:20px"
      >
        添加分类
      </el-button>

      <el-button
        size="small"
        style="margin-left: 20px"
        @click="addMaterialType(2)"
        icon="el-icon-minus"
        type="info"
        plain
      >
        删除素材分类
      </el-button>
           <el-button
            size="small"
            style="margin-left: 20px; margin-top: 20px"
            @click="search"
            icon="el-icon-search"
            type="primary"
          >
            搜索
          </el-button>
      <div class="neirong">
        <div class="left">
          <el-table :data="tableData" style="width: auto">
            <el-table-column prop="type_name" label="素材分类名称" width="auto">
            </el-table-column>
            <el-table-column prop="id" label="素材分类id" width="auto">
            </el-table-column>
            <el-table-column prop="pid" label="素材分类父id" width="auto">
            </el-table-column>
            <el-table-column prop="is_nav" label="是否为导航菜单" width="auto">
              <template slot-scope="scope">
                <el-switch
                  disabled
                  :active-value="1"
                  :inactive-value="0"
                  v-model="scope.row.is_nav"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="auto">
              <template slot-scope="scope">
                <el-button @click="edit(scope.row)" type="primary" size="small">
                  <i class="el-icon-edit"></i>
                  编辑
                </el-button>
                <el-button @click="delet(scope.row)" type="danger" size="small">
                  <i class="el-icon-delete"></i>
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-wrapper">
            <el-pagination
              :current-page="page"
              :page-sizes="[10, 20]"
              :page-size="limit"
              background
              layout="total, sizes, prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 抽屉 -->
    <el-drawer
      title="修改素材分类"
      :visible.sync="drawer"
      :direction="direction"
    >
      <el-form ref="form" :model="detailRow" label-width="120px">
        <el-form-item label="素材分类名称:" required>
          <el-input
            style="width: 250px"
            clearable
            v-model="detailRow.type_name"
          ></el-input>
        </el-form-item>
        <el-form-item label="素材分类图标:">
          <div class="flex-c-s" style="align-items: start">
            <div
              v-for="(item, index) in detailRow.iconList"
              :key="index"
              class="mr15"
            >
              <el-upload
                :multiple="false"
                class="avatar-uploader"
                :action="upload_url"
                :show-file-list="false"
                :on-success="
                  (response, file, fileList) =>
                    handleAvatarSuccess5(response, file, fileList, index)
                "
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="item" :src="item" class="avatar" />
                <img
                  v-if="item"
                  src="@/button/img_del.png"
                  class="img-del"
                  @click.stop="delImg5(index)"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </div>
          </div>
          <div class="tip-yellow">
            温馨提示：只能上传jpg、png、jpeg文件，最多1张
          </div>
        </el-form-item>
          <el-form-item  label="所属上级分类:">
      <el-select
        @change="changeSelect3"
  
        clearable
        v-model="value3"
        placeholder="选择修改上级分类"
      >
        <el-option
          v-for="item in options3"
          :key="item.value"
          :label="item.type_name"
          :value="item.id"
        >
        </el-option>
      </el-select>
        </el-form-item>
        <el-form-item label="排序:">
          <el-input-number
            v-model="detailRow.sort"
            :min="0"
            :max="10"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="是否为导航菜单:">
          <el-switch
            :active-value="1"
            :inactive-value="0"
            v-model="value"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
        <el-row>
          <el-button
            type="primary"
            @click="sureChange"
            style="margin-left: 20px"
            >确认修改</el-button
          >
          <el-button @click="cancel">取消</el-button>
        </el-row>
      </el-form>
    </el-drawer>
        <!-- 新增类型对话框 -->
    <el-dialog
      :title="typeTitle"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span v-if="typeTitle == '添加提示'">选择一级分类：</span>
      <span v-if="typeTitle == '审核提示'">审核处理：</span>
      <el-select
        v-if="typeTitle == '添加提示'"
        style="width: 300px"
        clear
        v-model="typeOne_id"
        clearable
        placeholder="请选择素材类型"
      >
        <el-option
          v-for="item in options7"
          :key="item.id"
          :label="item.type_name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-row style="margin-top: 10px">
        <span style="margin-left: 28px" v-if="typeTitle == '添加提示'"
          >类型名称：</span
        >
        <el-input
          v-if="typeTitle == '添加提示'"
          style="width: 300px"
          v-model="inputName"
          placeholder="请输入类型名称"
        ></el-input>
      </el-row>
          <el-row style="margin-top: 10px">
                  <span style="margin-left:28px" v-if="typeTitle == '添加提示'">素材来源：</span>
              <el-select
               v-if="typeTitle == '添加提示'"
          size="small"
          v-model="source_type"
          clearable
          placeholder="选择素材来源"
        >
          <el-option
            v-for="item in sourceList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
          </el-row>

      <el-form style="margin-top: 10px" v-if="typeTitle == '添加提示'">
        <el-form-item label-width="100px" label="icon图标:" required>
          <div class="flex-c-s" style="align-items: start">
            <div v-for="(item, index) in iconList" :key="index" class="mr15">
              <el-upload
                :multiple="false"
                class="avatar-uploader"
                :action="upload_url"
                :show-file-list="false"
                :on-success="
                  (response, file, fileList) =>
                    handleAvatarSuccess6(response, file, fileList, index)
                "
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="item" :src="item" class="avatar" />
                <img
                  v-if="item"
                  src="@/button/img_del.png"
                  class="img-del"
                  @click.stop="delImg6(index)"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </div>
          </div>
          <div class="tip-yellow">
            温馨提示：只能上传jpg、png、jpeg文件，最多1张
          </div>
        </el-form-item>
      </el-form>
    <el-form style="margin-top: 10px" v-if="typeTitle == '添加提示'">
       <el-form-item label-width="100px" label="排序:">
         <el-input-number v-model="num"  :min="0" :max="99999" label="描述文字"></el-input-number>
         </el-form-item>
    </el-form>
      <el-row>
        <span v-if="typeTitle == '删除提示'">选择一级分类：</span>
        <el-select
          clear
          v-if="typeTitle == '删除提示'"
          size="small"
          v-model="materialType2"
          clearable
          placeholder="选择需要删除的素材类型"
        >
          <el-option
            v-for="item in options7"
            :key="item.id"
            :label="item.type_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-row>

      <el-radio-group
        v-if="typeTitle == '审核提示'"
        v-model="checkStus"
        @change="changeCHck"
      >
        <el-radio label="通过"></el-radio>
        <el-radio label="驳回"></el-radio>
        <el-radio label="删除"></el-radio>
      </el-radio-group>
      <div style="margin-top: 20px" v-if="checkStus == '驳回'">
        <span style="margin-right: 10px">驳回原因:</span
        ><el-input
          clearable
          style="width: 300px"
          v-model="reject"
          placeholder="请输入驳回原因"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureAddName">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { gettype, edittype, deltype, typelist,addtype,sourcelist} from "@/Api/operation";
import { ceshi } from "@/utils/requset";
import { Message } from "element-ui";
import { MessageBox } from "element-ui";
export default {
  data() {
    return {
      dialogVisible:false,
      inputName:'',
      num:1,
         typeTitle: "",
      placeholder: "",
      typeOne_id: "",
      checkStus: "",
        materialType2: "", // 删除素材类型
      iconList: [""],
      options: [],
      options3: [],
       options7: [], // 一级分类下拉列表
      value2: "",
      value3: "",
      value: 0,
      drawer: false,
      direction: "rtl",
      loadingTitle: "加载中...",
      loading: false,
      page: 1,
      limit: 10,
      total: 0,
      source_type:'',// 素材来源
      tableData: [],
      sourceList:[],// 素材来源
          upload_url: ceshi + "/common/uploadFile/upload",
      detailRow: {},
    };
  },
  methods: {
    // 获取一级分类列表
    getOne() {
      let that = this;
      gettype({
        level: 1,
      }).then((res) => {
        if (res.code == 1) {
          that.options = res.data;
          let arr = JSON.parse(JSON.stringify(res.data))
          arr.unshift({id:0,type_name:'顶级分类'})
          that.options3 = arr;
          that.tableData = res.data;
        }
      });
    },
      handleClose(done) {
      done();
    },
        beforeAvatarUpload(file) {
      this.loading = true;
      this.loadingTitle = "上传中...";
      console.log(file);
      // const isLt5M = file.size / 1024 / 1024 < 2;
      // if (!isLt5M) {
      //   this.$message.error("上传图片大小不能超过 2MB!");
      // }
      // return isLt5M;
    },
      // 获取素材资源列表
    getSourceList(){
      sourcelist({}).then(res=>{
        if(res.code == 1){
          this.sourceList = res.data
        }
      })
    },
       // 删除icon
    delImg6(index) {
      console.log(index);
      const arrTemp = JSON.parse(JSON.stringify(this.iconList));
      arrTemp[index] = "";
      this.iconList = arrTemp;
    },
        // 上传icon
    handleAvatarSuccess6(res, file, fileList, index) {
      this.loading = false;
      this.loadingTitle = "加载中...";
      // this.form.banner_chart[index] = res.data.fullurl
      if (res.code != 1) {
        Message({
          message: res.msg,
          type: "warning",
        });
        return;
      }
      const arrTemp = JSON.parse(JSON.stringify(this.iconList));
      arrTemp[index] = res.data.fullurl;
      this.iconList = arrTemp;
    },
    sureAddName(){
       // 删除操作
      if (this.typeTitle == "删除提示") {
        if (!this.materialType2) {
          Message({
            message: "删除类型名称，不能为空",
            type: "error",
          });
          return;
        }

        deltype({ id: this.materialType2 }).then((res) => {
          if (res.code == 1) {
            Message({
              message: "操作成功",
              type: "success",
            });
            this.dialogVisible = false;
            this.getOneType();
          } else {
            Message({
              message: "操作失败",
              type: "error",
            });
          }
        });
        return;
      }
      // 添加操作
      if (!this.inputName) {
        Message({
          message: "添加类型名称，不能为空",
          type: "error",
        });
        return;
      }
      if (this.iconList[0] == "") {
        Message({
          message: "icon图标,不能为空",
          type: "error",
        });
        return;
      }

      addtype({
        type_name: this.inputName,
        icon: this.iconList[0],
        pid: this.typeOne_id,
        company_id:this.source_type,
        sort: this.num,
        token: sessionStorage.getItem("token")
      }).then((res) => {
        if (res.code == 1) {
          Message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getOneType();
        } else {
          Message({
            message: "操作失败",
            type: "error",
          });
        }
      });
    },
       // 获取一级分类
    getOneType() {
      gettype({ level: 1 })
        .then((res) => {
          if (res.code == 1) {
            res.data.unshift({ type_name: "顶级分类", id: 0 });
            this.options7 = res.data;
          }
        })
        .catch(() => {});
    },
        // 添加素材类型
    addMaterialType(type) {
      let that = this;
      this.dialogVisible = true;
      switch (type) {
        case 1:
          that.typeTitle = "添加提示";
          that.placeholder = "请输入需要添加的类型名称";
          break;
        case 2:
          that.typeTitle = "删除提示";
          that.placeholder = "请输入需要删除的类型名称";
          break;

        default:
          break;
      }
    },
    search(){
      this.getList()
    },
    // 获取二级分类列表
    getList() {
      let that = this;
      typelist({
        pid: this.value2,
      }).then((res) => {
        if (res.code == 1) {
          that.tableData = res.data.rows;
        }
      });
    },
    edit(row) {
      console.log(row.is_nav);

      this.value = row.is_nav;
      this.drawer = true;
      (row.iconList = [row.icon]),
        (this.detailRow = JSON.parse(JSON.stringify(row)));
    },
    delet(row) {
      let that = this;
      MessageBox({
        title: "提示",
        type: "warning",
        message: "删除不可恢复,确定删除吗？",
        callback(res) {
          if (res == "confirm") {
            deltype({
              id: row.id,
            }).then((res) => {
              if (res.code == 1) {
                Message({
                  message: res.msg,
                  type: "success",
                });
                that.getList();
              } else {
                Message({
                  message: res.msg,
                  type: "error",
                });
              }
            });
          }
        },
      });
    },
    sureChange() {
      if (!this.detailRow.type_name) {
        Message({
          message: "素材分类名称不能为空",
          type: "warning",
        });
        return;
      }
      let data = this.detailRow;
      edittype({
        type_name: data.type_name,
        icon: data.iconList[0],
        is_nav: this.value,
        sort: data.sort,
        id: data.id,
        pid: this.value3 || this.value3==0?this.value3:data.pid,
      }).then((res) => {
        if (res.code == 1) {
          Message({
            message: res.msg,
            type: "success",
          });
          this.value3 = ''
          this.drawer = false;
          if (this.value2.length <= 0) {
            this.getOne();
            return;
          }
          this.getList();
        }else{
            Message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    cancel() {
      this.drawer = false;
    },
    changeSelect(e) {
      this.value2 = e
    },
  changeSelect3(e) {
      this.value3 = e
    },
    // 上传icon
    handleAvatarSuccess5(res, file, fileList, index) {
      this.loading = false;
      this.loadingTitle = "加载中...";
      // this.form.banner_chart[index] = res.data.fullurl
      if (res.code != 1) {
        Message({
          message: res.msg,
          type: "warning",
        });
        return;
      }
      const arrTemp = JSON.parse(JSON.stringify(this.detailRow.iconList));
      arrTemp[index] = res.data.fullurl;
      this.detailRow.iconList = arrTemp;
    },
    // 删除icon
    delImg5(index) {
      console.log(index);
      const arrTemp = JSON.parse(JSON.stringify(this.detailRow.iconList));
      arrTemp[index] = "";
      this.detailRow.iconList = arrTemp;
    },
    // 改变页码
    handleCurrentChange(val) {
      this.loading = true;
      this.page = val;
      this.getList();
    },
    // 改变一页的数量
    handleSizeChange(val) {
      this.loading = true;
      this.page = 1;
      this.limit = val;
      this.getList();
    },
  },
  created() {
    this.getOne();
    this.getOneType()
    this.getSourceList()
  },
};
</script>
<style lang="scss" >
.app-container {
  .white-container {
    height: 100vh;
    overflow: auto;
    .add {
      margin-top: 20px;
    }
    .neirong {
      min-width: 600px;
      min-height: 600px;
      margin-top: 20px;
      display: flex;
      .left {
        width: 100%;
      }
    }
  }
}
</style>
