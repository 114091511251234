import { request } from '@/utils/requset.js'

// 素材统计
export function sucaiCount(data) {
  return request({
    url: '/api/sucai/tongji',
    method: 'post',
    data
  })
}
// 获取素材分类
export function gettype(data) {
  return request({
    url: '/api/sucai/gettype',
    method: 'post',
    data
  })
}
export function edittype(data) {
  return request({
    url: '/api/sucai/edittype',
    method: 'post',
    data
  })
}
// 获取素材来源列表
export function sourcelist(data) {
  return request({
    url: '/api/sucai/sourcelist',
    method: 'post',
    data
  })
}

// 修改审核状态
export function changeStatus(data) {
  return request({
    url: '/api/sucai/change_status',
    method: 'post',
    data
  })
}
// 修改素材内容
export function editone(data) {
  return request({
    url: '/api/sucai/editsucai',
    method: 'post',
    data
  })
}
// 获取素材列表
export function sucaiList(data) {
  return request({
    url: '/api/sucai/list',
    method: 'post',
    data
  })
}
// 根据一级id获取二级列表
export function typelist(data) {
  return request({
    url: '/api/sucai/typelist',
    method: 'post',
    data
  })
}

// 改变用户状态

export function setstatus(data) {
  return request({
    url: '/api/user/setstatus',
    method: 'post',
    data
  })
}

// 用户列表
export function userList(data) {
  return request({
    url: '/api/user/lists',
    method: 'post',
    data
  })
}

// 增加素材内容
export function sucaiAdd(data) {
  return request({
    url: '/api/sucai/add',
    method: 'post',
    data
  })
}
// 删除素材分类
export function deltype(data) {
  return request({
    url: '/api/sucai/deltype',
    method: 'post',
    data
  })
}
// 添加素材分类
export function addtype(data) {
  return request({
    url: '/api/sucai/addtype',
    method: 'post',
    data
  })
}
// 添加banner
export function addbaner(data) {
  return request({
    url: '/api/banner/add',
    method: 'post',
    data
  })
}
// 修改banner
export function editbaner(data) {
  return request({
    url: '/api/banner/edit',
    method: 'post',
    data
  })
}
// 获取banner
export function getbaner(data) {
  return request({
    url: '/api/banner/getrow',
    method: 'post',
    data
  })
}



